#menu h3:hover{
    color: rgb(255, 255, 255);
}




.btn-navmain{
    transition: 0.5s;
    border-radius: 100px;
    box-shadow: 0px 0px 0px black;
}

.btn-navmain:hover{
    transform: scale(1.05);
    box-shadow: 1px 3px 10px black;
}

.btn-navmain-close{
    transition: 0.5s;
    border: none;
}

.btn-navmain-close:hover{
    transform: scale(1.1)
}