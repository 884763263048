@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins'), url(./Poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins'), url(./Poppins/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-EBold';
  src: local('Poppins'), url(./Poppins/Poppins-Bold.ttf) format('truetype');
}


code {
  font-family: monospace;
}
