.report{
    width: 100%;
    min-height: 400px;
    
}
.cards{
    margin: auto 3%;
    background: transparent;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

}

.report_card{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-width: 250px;
      max-width: 350px;
      text-align: center;
      min-height: 250px;
      border: none;
      /* box-shadow: 1px 2px 10px 5px rgba(73, 75, 59, 0.452); */
      margin: 0 1.5rem;
      transition: 0.3s;
      margin-top: 10vh;
      transition: 0.5s;
}
.report_card:hover{
    transform: scale(1.1);
    background-color: rgba(73, 75, 59, 0.452);
}
.reportcard_icon{
    margin: auto;
}

.reportcard_icon img{
    width: 100px;   
}